import React from 'react';
import '../assets/styles/pages/About.css'; 
import cardImage from '../assets/images/4087398-scaled.jpg'; 
// import Image2 from '../assets/images/PHOTO-2023-08-01-10-26-28.remini-enhanced.jpg'; 
import Image1 from '../assets/images/PHOTO-2023-08-01-10-25-46.remini-enhanced.jpg';
import Image2 from '../assets/images/istockphoto-1243522110-612x612.remini-enhanced.jpg';
import Image3 from '../assets/images/PHOTO-2023-08-01-10-27-21.remini-enhanced.jpg';

function About() {
    const MainContent = () => {
        return (
            <div className="main-content">
              <div className="content-section">
                <h1>About Glitter</h1>
                <p>Glitter is an exciting startup created by a bunch of finance and tech wizards. <br/> <br/>
                  Our big idea? We want to make it super easy for newbies in trading to find great as well as low risk options to trade without all the usual headache. <br/> <br/>
                  Our mission is pretty simple: we're here to help you navigate the options market like a pro, making smart investment choices without getting lost in the jargon. <br/><br/>
                  Our platform? It's a breeze to use, no matter if you're just starting out or you've been trading for years. We're all about making your trading journey smoother, simpler, and a lot more fun.</p>
              </div>
              <div className="card">
                <img src={cardImage} alt="Card" className="card-image" />
              </div>
            </div>
          );
        };

        // BenefitsBar sub-component
    const BenefitsBar = () => {
        return (
            <div className="benefits-bar">
                <div className="benefit">
                <span>✓ Easy & convenient</span>
                </div>
                <div className="benefit">
                <span>✓ Low-risk investing</span>
                </div>
                <div className="benefit">
                <span>✓ Start small & grow big</span>
                </div>
                <div className="benefit">
                <span>✓ User friendly</span>
                </div>
            </div>
        );
    };
    
    //FAQ section:
    const faqSection = () => {
      return (
        <div>
            <div className="secret">
                <h2 className="heading2">Frequently Asked Questions!</h2>
            </div>

            <div className="faq-container">
                <div className="faq1">
                    <h2>1. What is options trading?</h2>
                    <p>Basic strategies include buying or selling covered calls, or buying protective puts, however, we solely focus on the simplicity of investing which is achieved with buying call options.</p>  
                    <img src={Image1} alt="Card" className="img"/>
                </div>

                <div className="faq2">
                    <h2>2. How do I manage risk?</h2>
                    <p>Risk management is essential. Set a stop-loss order, use proper position sizing, and never invest more than you're willing to lose.</p>    
                    <img src={Image2} alt="Card" className="img"/>      
                </div>

                <div className="faq3">
                    <h2>3. What makes a successful trader?</h2>
                    <p>Discipline, education, and a touch of madness make the perfect recipe for a prosperous trading career.</p>    
                    <img src={Image3} alt="Card" className="img"/>             
                </div>
            </div>
        </div>
      );
    };

  //Meet The Team section:
  // const meetTheTeam = () => {
  //   return (     
  //     <div className="mtt-container">
  //       <div className="secret mtt-upper">
  //           <h2 className="heading2">Meet The Team!</h2>
  //       </div>

  //       <div class="mtt-middle">
  //             <div className="mtt-card">
  //             <div className="mtt-card-front">
  //               <p className="name">Pragati Makani</p>
  //               <p className="title">Co-Founder, Product Owner</p>
  //             </div>
  //             <div className="mtt-card-back">
  //               <p>Pragati is a recent graduate from San Francisco State University.</p>
  //             </div>
  //         </div>
              
  //         <div className="mtt-card">
  //           <div className="mtt-card-front">
  //             <p className="name">Ingrid Marianna</p>
  //             <p className="title">Founder</p>
  //           </div>
  //           <div className="mtt-card-back">
  //             <p>Ingrid founded Glitter alongside Pragati.</p>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="mtt-lower">
  //         <div className="mtt-card">
  //           <div className="mtt-card-front">
  //             <p className="name">Hayden Coke</p>
  //             <p className="title">Backend Engineer</p>
  //           </div>
  //           <div className="mtt-card-back">
  //             <p>Hayden is a current student from San Francisco State University.</p>
  //           </div>
  //         </div>
        
  //         <div className="mtt-card">
  //           <div className="mtt-card-front">
  //             <p className="name">Gayatri Patil</p>
  //             <p className="title">Backend Engineer</p>
  //           </div>
  //           <div className="mtt-card-back">
  //             <p>Gayatri and Hayden make up the Backend Team.</p>
  //           </div>
  //         </div>

  //         <div className="mtt-card">
  //           <div className="mtt-card-front">
  //             <p className="name">Omar Dajani</p>
  //             <p className="title">Frontend Engineer</p>
  //           </div>
  //           <div className="mtt-card-back">
  //             <p>Omar is a current student from San Francisco State University.</p>
  //           </div>
  //         </div>
  //       </div>
  //   </div>
  //   );
  // };

  const About = () => {
    return (
      <div className="about-page">
        <MainContent />
        <BenefitsBar />
        {faqSection()}
        {/* <BenefitsBar /> */}
        {/* {meetTheTeam()} */}
      </div>
    );
  };
  return <About />;
}

export default About;