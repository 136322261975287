import React from 'react';
import '../assets/styles/components/Footer.css';


const Footer = () => {
    return (
        <footer>
            <p><h4>glitter-fund</h4></p>
            <p>Attention Traders: The following pages may cause paradigm shifts, awaken the inner trader, and result in extraordinary wealth creation. Please fasten your seatbelts and keep your eyes on the charts at all times.</p>
            <p>Disclaimer: this tool is provided to users for filtering price to liquidity and in no way provides investment recommendations.</p>
            <p>Personal Data and Information: We protect the privacy of your information and will never share your data. Your data is protected with 256-bit SSL encryption and never stored on any phone, tablet, or computer. We take your privacy seriously.</p>
            <p>© 2023 glitter, Inc. All rights reserved.</p>
        </footer>
    );
};

export default Footer;