import React from 'react';
import '../assets/styles/components/Results.css';

const Results = ({ data, itemsPerPage, currentPage, totalPages, onPageChange }) => {
  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  // Extract the subset of data for the current page
  const currentPageData = data.slice(startIndex, endIndex);

  // Map the subset of data to create placeholderData
  const placeholderData = currentPageData.map((item, index) => ({
    no: startIndex + index + 1,
    company: item.company,
    price: item.price,
    maxPrice: item.maxPrice,
    strikePrice: item.strikePrice,
    volume: item.volume,
    expirationDate: item.expirationDate,
  }));

  return (
    <div className="result-container">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Company</th>
              <th>Price</th>
              <th>Max Price</th>
              <th>Strike-Price</th>
              <th>Volume</th>
              <th>Expiration Date</th>
            </tr>
          </thead>
          <tbody>
            {placeholderData.map((item) => (
              <tr key={item.no}>
                <td>{item.no}</td>
                <td>{item.company}</td>
                <td>{item.price}</td>
                <td>{item.maxPrice}</td>
                <td>{item.strikePrice}</td>
                <td>{item.volume}</td>
                <td>{item.expirationDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Links */}
      <div className="pagination">
        <span className="step-links">
          {currentPage > 1 && (
            <>
              <button onClick={() => onPageChange(1)}>&laquo; first</button>
              <button onClick={() => onPageChange(currentPage - 1)}>previous</button>
            </>
          )}

          <span className="current">
            Page {currentPage} of {totalPages}.
          </span>

          {currentPage < totalPages && (
            <>
              <button onClick={() => onPageChange(currentPage + 1)}>next</button>
              <button onClick={() => onPageChange(totalPages)}>last &raquo;</button>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Results;
