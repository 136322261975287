import React from 'react';
import '../assets/styles/pages/Home.css'; 
import videoSrc from '../assets/images/Glitter - Filter Tutorial.mp4'


const Home = () => {
    return (
        <div className="main">
            <main className="content">
                {/* <h1 className="stress">Enjoy Stress Free Investing</h1> */}
                <h1 className="home1">Options Trading Made Easy with Innovation</h1>
                <p className="p9">You don't need a LOT of money to make money, find low prices on your favorite stock options!</p>

                {/* <div className="video-wrapper">
                <iframe className="video" src="https://youtu.be/Fz29UkB0hvo"></iframe>
                </div> */}

                <video className="video10" autoPlay muted loop onLoadedMetadata={(e) => e.target.playbackRate = 1.25}>
                    <source src={videoSrc} type="video/mp4" />
                </video>

                <div className="service">
                    <h1 className="heading1">Connect with us!</h1>
                </div>

                <div className="grid-container2">
                    <div className="grid-item2">
                        <h3>Optimize your options trading investments</h3>
                        <p>With our filter system, you can narrow down your search by ticker symbol, expiration date and price.</p>                
                    </div>

                    <div className="grid-item2">
                        <h3>Get in touch with our team</h3>
                        <p>Our team is friendly and helpful, and are always willing to go the extra mile to help you. Email us at hi@glitterfund.xyz for any inquiries, assistance or feedback.</p>
                    </div>

                    <div className="grid-item2">
                        <h3>Connect with our community on LinkedIn</h3>
                        <p>Join our exclusive investor community on LinkedIn today and take advantage of a platform designed to empower your growth and success.</p>
                    </div>
                    
                    <div className="grid-item2">
                        <h3>Stay informed through our Twitter</h3>
                        <p>In this fast-paced financial world, follow us on Twitter to receive real-time updates, market analysis, expert opinions, and to give you a competitive edge in the trading arena.</p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Home;
